import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DestinationLoading = () => {
  return (
    <div className="carousel-loading destination-carousel">
      <div className="loading-list">
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default DestinationLoading;
